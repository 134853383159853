import css from 'styled-jsx/css'
import SwiperCore, { SwiperOptions, Lazy } from 'swiper'

import {
  iconPath,
  imagePath,
  previewImage,
  videoPath,
  previewVideo,
  useCdnBasePath
} from '@ui/helpers'
import { useEffect, useRef } from 'react'
import { thumbImage } from '@libs/client'
import { useIsPreview } from '@ui/hooks'

SwiperCore.use([Lazy])

const sThumb = thumbImage('96x96')

type ImageCarouselProps = {
  imgUrls: string[]
  activeIndex: number
  changedVariantImage: number
  onImageChange: (index: number) => void
  fallbackImgs?: S3Types.fallback
}

const swiperOptions: SwiperOptions = {
  centeredSlides: true,
  // lazy: true,
  loop: true,
  preloadImages: false,
  slidesPerView: 5,
  slideToClickedSlide: true,
  spaceBetween: 8,
  speed: 250,
  watchSlidesVisibility: true
}

export default function ImageCarousel({
  imgUrls = [],
  activeIndex = 0,
  changedVariantImage,
  onImageChange,
  fallbackImgs = {}
}: ImageCarouselProps) {
  const isPreview = useIsPreview()
  const basepath = useCdnBasePath()
  function onSlideChange() {
    onImageChange(swiperRef.current?.realIndex || 0)
  }
  const swiperRef = useRef<SwiperCore>()
  useEffect(() => {
    swiperRef.current = new SwiperCore('.swiper-container', swiperOptions)
    swiperRef.current.slideToLoop(activeIndex)
    swiperRef.current.on('slideChange', () => onSlideChange())
  }, [])
  useEffect(() => {
    swiperRef.current?.slideToLoop(activeIndex)
  }, [changedVariantImage])

  return (
    <div className="image-carousel columns is-mobile is-vcentered is-gapless">
      <style jsx>{scopedStyle}</style>
      <div
        className="swiper-button column is-1 is-paddingless has-text-centered"
        onClick={() => swiperRef.current?.slidePrev()}
      >
        <img src={iconPath('chevron_left.svg')} alt="chevron left" />
      </div>
      <div className="column is-10">
        <div className="swiper-container">
          <div className="swiper-wrapper">
            {imgUrls.map((imgUrl, idx) => (
              <div key={idx} className="swiper-slide">
                <figure className="image is-square">
                  {imgUrl?.includes('.mp4') ? (
                    <>
                      {!isPreview ? (
                        <picture>
                          <source srcSet={sThumb(fallbackImgs?.[imgUrl])} />
                          <img
                            className="swiper-lazy"
                            src={sThumb(fallbackImgs?.[imgUrl])}
                            alt="carousel"
                          />
                        </picture>
                      ) : (
                        <video className="video-vap" autoPlay loop muted playsInline>
                          <source
                            src={
                              isPreview
                                ? previewVideo(imgUrl, basepath)
                                : videoPath(imgUrl, basepath)
                            }
                            type="video/mp4"
                          />
                        </video>
                      )}
                    </>
                  ) : (
                    <picture>
                      <source srcSet={isPreview ? previewImage(imgUrl) : sThumb(imgUrl)} />
                      {!isPreview && fallbackImgs?.[imgUrl] ? (
                        <source
                          srcSet={
                            isPreview ? previewImage(imgUrl) : imagePath(fallbackImgs?.[imgUrl])
                          }
                        />
                      ) : (
                        <></>
                      )}
                      <img
                        src={isPreview ? previewImage(imgUrl) : sThumb(imgUrl)}
                        className="swiper-lazy"
                        alt="swiper lazy"
                      />
                    </picture>
                  )}
                </figure>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="swiper-button column is-1 is-paddingless has-text-centered"
        onClick={() => swiperRef.current?.slideNext()}
      >
        <img src={iconPath('chevron_right.svg')} alt="chevron right" />
      </div>
    </div>
  )
}

const scopedStyle = css`
  .image-carousel {
    .swiper-button {
      :hover {
        cursor: pointer;
      }

      img {
        width: 14px;
        height: 14px;
        vertical-align: middle;
      }
    }

    .swiper-lazy-preloader {
      margin: auto;
      width: 50%;
      height: 50%;
      top: 25%;
      left: 25%;
      opacity: 0.5;
    }

    .swiper-container {
      .swiper-slide {
        background-color: white;
        border: solid 1px #dbdbdb;
        border-radius: 5px;
        box-sizing: border-box;

        &-active {
          border: solid 1px #4a4a4a;
          opacity: 1;
        }

        :hover {
          cursor: pointer;
        }

        img {
          border-radius: 4px;
          object-fit: cover;
        }
      }
    }
  }

  .image.is-square video {
    object-fit: cover;
  }
`
