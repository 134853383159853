import { genSid } from '@libs/client'
import React, { FC, useEffect, useState } from 'react'
import css from 'styled-jsx/css'

type StarRatingProps = {
  editable?: boolean
  rating?: number
  onChange?: (idx: number) => void
  className?: string
  colorActive?: string
  colorInActive?: string
}

const StarRating: FC<StarRatingProps> = ({
  editable = false,
  rating = 0,
  onChange,
  className,
  colorActive = '#ffc866',
  colorInActive = '#d1d1d1'
}) => {
  const [currentRating, setCurrentRating] = useState(rating)
  const idUnique = genSid()

  useEffect(() => setCurrentRating(rating), [rating])

  const onMouseEnter = (idx: number) => () => editable && setCurrentRating(idx)
  const onMouseLeave = () => editable && setCurrentRating(rating)

  const onClick = (idx: number) => () => onChange?.(idx)

  const rateNum = (idx: number) => (currentRating - idx < 0 ? currentRating - idx + 1 : 1)
  return (
    <div className={`star-rating is-inline-flex ${className || ''}`}>
      <style jsx global>
        {globalStyle}
      </style>
      {[1, 2, 3, 4, 5].map((idx) => {
        const starId = `star-rate-${idUnique}-${idx}`
        return (
          <svg
            key={`star-rate-${idUnique}-${idx}`}
            className={`star ${editable ? 'editable' : ''}`}
            height="13"
            viewBox="0 0 14 13"
            width="14"
            xmlns="http://www.w3.org/2000/svg"
            onMouseEnter={onMouseEnter(idx)}
            onMouseLeave={onMouseLeave}
            onClick={onClick(idx)}
          >
            {!editable && (
              <defs>
                <linearGradient id={starId} x1="0" y1="0" x2="1" y2="0">
                  <stop id="yellow" offset={rateNum(idx)} stopColor={colorActive} />
                  <stop id="gray" offset="0" stopColor={colorInActive} />
                </linearGradient>
              </defs>
            )}
            <path
              d="m73.9513043 10.85-4.1400526 1.813119.4557424-4.47340357-3.0144356-3.35283439 4.4217169-.95159647 2.2770289-3.88528457 2.277029 3.88528457 4.4217169.95159647-3.0144356 3.35283439.4557424 4.47340357z"
              fill={
                editable ? (currentRating < idx ? colorInActive : colorActive) : `url(#${starId})`
              }
              fillRule="evenodd"
              transform="translate(-67)"
            />
          </svg>
        )
      })}
    </div>
  )
}

const globalStyle = css.global`
  .star-rating {
    height: 14px;

    .star {
      height: 100%;
      width: auto;

      &:not(:last-child) {
        margin-right: 3px;
      }

      &.editable {
        cursor: pointer;
      }
    }
  }
`

export default StarRating
