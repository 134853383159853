import React, { FC, useEffect, useMemo, useRef } from 'react'
import { useIsPreview, useSetting, useVersionCookie } from '@ui/hooks'
import { thumbImage } from '@libs/client'
// import getConfig from 'next/config'
import getConfig from 'next/config'

interface DescriptionProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string
  description?: string
}
const config = getConfig().publicRuntimeConfig // Move getConfig call inside the hook

const sThumb = thumbImage('96x96')

const Description: FC<DescriptionProps> = ({ label = '', description = '', className = '' }) => {
  const isPreview = useIsPreview()
  const ref = useRef(null)
  const [sellpageSetting] = useSetting('sellpage')
  const fallbackImg = sellpageSetting?.fallback_img
  const versionCookie = useVersionCookie()
  const [storeSetting] = useSetting('store')
  const cdnBasePath = config.cdnBasePath
  const shortCdnBasePath = cdnBasePath?.replace('https://', '') ?? ''
  const isVpl1 = versionCookie === 'vpl1' || storeSetting?.version === 'vpl1'
  const isNotArgo = storeSetting?.version == 'vna2' || versionCookie == 'vna2'

  useEffect(() => {
    window.addEventListener('touchstart', () => {
      const videoElement: any = document.getElementsByTagName('video')
      if (videoElement.length) {
        ;[...videoElement]?.forEach(async (element: HTMLMediaElement) => {
          if (!element.controls) {
            element.play()
          }
        })
      }
    })
    return () => {
      window.removeEventListener('touchstart', () => {
        console.log('clean')
      })
    }
  }, [ref])

  const editedDescription = useMemo(() => {
    return (
      description
        ?.replace(/http:\/\//gm, 'https://')
        // .replace(/(<img([^>]*))( src=)/gm, '$1 alt="" data-src=')
        ?.replace(/<video([\S\s]*?)>([\S\s]*?)<\/video>/g, (u: string) => {
          if (isVpl1) {
            const video = (u || '')
              .replaceAll(`controls="controls"`, (x) => {
                if (u.includes('#audio')) return x
                return `autoplay loop muted playsinline class="lazy"`
              })
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              .replaceAll(/src="([^'"]*)"/g, (_x, y: string) => {
                return `data-src="${y}"`
              })
              .replaceAll(/poster="([^'"]*)"/g, () => {
                return ``
              })
            return video
          }
          if (isNotArgo) {
            const video = (u || '')
              .replaceAll(`controls="controls"`, (x) => {
                if (u.includes('#audio')) return x
                return `autoplay loop muted playsinline`
              })
              .replaceAll(`${config.cdnVideo}`, `${config.cdnVideoNa}`)
            return video
          }
          return (u || '').replace(`controls="controls"`, (x) => {
            if (u.includes('#audio')) return x
            return 'autoplay loop muted playsinline'
          })
        })
        .replace(/<img([\w\W]+?)>/g, (_a) => {
          if (isPreview) return _a
          let src = ''
          let originSrc = ''
          const imgTag = _a.replace(/src="([^'"]*)"/g, (_x, y: string) => {
            originSrc = y
            const image = y
              .replace(cdnBasePath, '')
              .replace(shortCdnBasePath, '')
              .split('/')
              .filter((z) => z)
              ?.join('/')
            y = y.includes('https://') ? y : `https://${y}`
            src = y
            return `class="lazyload blur-up ${
              isVpl1 ? '' : 'lazypreload'
            }" alt="" data-src="${y}" src="${sThumb(image)}"`
          })
          const fallback = fallbackImg?.[originSrc]
          const pictureTag = `<picture>
            <source data-srcset="${src}" />
            ${
              fallback
                ? `<source data-srcset="${
                    fallback.includes('https://') ? fallback : `https://${fallback}`
                  }" />`
                : ''
            }
            ${imgTag}
        </picture>`
          return pictureTag
        })

        .replace(/<iframe/g, '<figure class="image is-16by9"><iframe class="has-ratio"')
        .replace(/<\/iframe>/g, '</iframe></figure>')
    )
  }, [description])

  if (!description) return null

  return (
    <div className={`description ${className || ''}`} ref={ref}>
      {label && (
        <div className="columns">
          <div className="column is-4 is-hidden-mobile">
            <div className="title--large">{label}</div>
          </div>
          <div className="column is-8">
            <div
              // v-lazy-container="{ selector: 'img' }"
              className="rich-text"
              dangerouslySetInnerHTML={{
                __html: editedDescription
              }}
            />
          </div>
        </div>
      )}
      {!label && (
        <div
          // v-lazy-container="{ selector: 'img' }"
          className="rich-text"
          dangerouslySetInnerHTML={{
            __html: editedDescription
          }}
        />
      )}
    </div>
  )
}

export default Description
