import css from 'styled-jsx/css'
import { SyncedProp } from '@ui/hooks'
import { useEffect } from 'react'
import { logger } from '@ui/analytics'
import { iconPath } from '@ui/helpers'

interface QuantityInputProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string
  isCta?: boolean
  isHorizontal?: boolean
  valueSyncedProp: SyncedProp<number>
  emitChange?: boolean
}

export default function QuantityInput({
  label = 'Quantity',
  isCta = true,
  isHorizontal = true,
  valueSyncedProp,
  emitChange = false,
  className
}: QuantityInputProps) {
  const [syncedValue, setSyncedValue] = valueSyncedProp

  useEffect(() => {
    emitChange && logger.logCart('quantity')
  }, [syncedValue])

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === '.') {
      event.preventDefault()
    }
  }

  const onChange = (event: any) => {
    setSyncedValue(parseInt(event.target.value, 10) || 1)
  }

  function increment() {
    setSyncedValue(syncedValue + 1)
  }

  function decrement() {
    setSyncedValue(Math.max(syncedValue - 1, 1))
  }
  return (
    <div
      className={`quantity-input field ${isHorizontal ? 'is-horizontal' : ''} ${
        isCta ? 'is-cta' : ''
      } ${className ?? ''}`}
    >
      <style jsx global>
        {globalStyle}
      </style>
      {label && (
        <div className="field-label is-normal">
          <label className="label is-capitalized has-text-left">{label}</label>
        </div>
      )}
      <div className="field-body">
        <div className="field has-addons">
          <p className="control">
            <a className="icon-button button left" onClick={decrement}>
              <img src={iconPath('minus.svg')} alt="" />
            </a>
          </p>
          <p className="control control-input">
            <input
              value={syncedValue}
              type="tel"
              className="input"
              onKeyPress={onKeyPress}
              onChange={onChange}
            />
          </p>
          <p className="control">
            <a className="icon-button button right" onClick={increment}>
              <img src={iconPath('plus.svg')} alt="" />
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

const globalStyle = css.global`
  .quantity-input {
    .icon-button {
      img {
        min-width: 12px;
        width: 12px;
        height: 12px;
      }
    }

    .button {
      &:focus,
      &:hover {
        box-shadow: none;
        border-color: #dbdbdb;
      }

      &.left {
        border-right: 0;
      }

      &.right {
        border-left: 0;
      }
    }

    .input {
      border-right: 0;
      border-left: 0;
      box-shadow: none;
      height: 36px;
      padding-right: 0 !important;
      padding-left: 0 !important;
      text-align: center;
      width: 2em;
      @media screen and (max-width: 360px) {
        font-size: 0.8rem;
      }

      &:focus,
      &:hover {
        box-shadow: none;
        border-color: #dbdbdb;
      }

      &[type='number'] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: textfield;
      }
      &[type='number']::-webkit-inner-spin-button,
      &[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
    }

    &.is-horizontal {
      align-items: center;

      .button,
      .input {
        height: 40px;
      }

      .button {
        padding: 5px 12px;
      }
    }

    &.is-cta {
      .button,
      .input {
        line-height: 20px;
        height: 55px;
        padding: 15px 12px;
      }
    }

    &.is-fullwidth {
      .control-input {
        width: calc(100% - 60px);

        .input {
          width: 100%;
        }
      }
    }

    &.is-fullwidth-mobile {
      @media screen and (max-width: 768px) {
        .control-input {
          width: calc(100% - 60px);

          .input {
            width: 100%;
          }
        }
      }
    }
  }
`
