import css from 'styled-jsx/css'
import BorderWrapper from './BorderWrapper'

type RadioButtonProps = {
  isHorizontal?: boolean
  propertyName: string
  values: string[]
  selectedProperty: string
  onPropertyChanged: (property: string) => void
  version?: string
  classVariant?: string
}

export default function RadioButton({
  isHorizontal = true,
  propertyName,
  values,
  selectedProperty,
  onPropertyChanged,
  classVariant
}: RadioButtonProps) {
  return (
    <div className={`radio-buttons field ${isHorizontal ? 'is-horizontal' : ''} ba10`}>
      <style jsx global>
        {globalStyle}
      </style>
      <div className="field-label is-normal">
        <label className="label is-capitalized has-text-left">{propertyName}</label>
      </div>
      <div className="field-body">
        <div className="field is-grouped is-grouped-multiline">
          {values.map((value, idx) => (
            <div key={`variant-${idx}`} className="control">
              <BorderWrapper type="text" isActive={values[idx] === selectedProperty}>
                <button
                  className={`button btn-text is-capitalized ${classVariant} ${
                    values[idx] === selectedProperty ? 'is-active' : 'is-inactive'
                  }`}
                  onClick={() => onPropertyChanged(values[idx])}
                >
                  {value}
                </button>
              </BorderWrapper>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const globalStyle = css.global`
  .radio-buttons {
    .field.is-grouped {
      .control {
        max-width: 100%;

        &:not(:last-child) {
          margin-right: 5px;
        }

        .button {
          max-width: 100%;
          text-align: justify;
          white-space: normal;
          font-size: 13px;
          height: auto;
          line-height: 18px;
          padding: 10px;
          min-width: 40px;
        }
      }

      &.is-grouped-multiline {
        .control:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }
    &.ba10 {
      padding-bottom: 7px;
      .button:focus:not(:active) {
        box-shadow: none;
      }
      .field-label {
        margin: 0;
        padding: 0 0 8px;
      }

      .field.is-grouped {
        .control {
          .button {
            min-width: auto;
          }
        }
      }

      .field.is-grouped.is-grouped-multiline {
        align-items: center;
        &.text-spacing {
          margin: 0 -4px;
        }
        .control {
          max-width: 100%;
          margin: 0px 5px 5px 0px;

          .button {
            max-width: 100%;
            text-align: justify;
            white-space: normal;
            font-size: 13px;
            min-width: 40px;
            line-height: 18px;

            &.btn-img {
              border: 1px solid #e0e3e9;
              box-sizing: border-box;
              padding: 0;
              border-radius: 4px;

              .variant-image {
                border-radius: 4px;
              }
            }
            &.btn-text {
              font-weight: 400;
              font-size: 13px;
              line-height: 18px;
              text-align: center;
              color: #363636;
              width: 100%;
              height: auto;
              padding: 10px;
              border-radius: 4px;
              border: 1px solid #dbdbdb;

              &.is-active {
                border: none;
                background-color: #e3f2fd;
              }
              &.is-variant {
                font-size: 13px;
                line-height: 18px;
                padding: 10px;
              }
            }
          }
        }
      }

      .label {
        font-weight: 700;
        font-size: 15px;
        line-height: 24px;
        color: #363636;

        /* letter-spacing: 0.09em; */

        .title-image {
          font-weight: 400;
          padding-right: 4px;
        }
      }
    }
  }

  @media screen and (min-width: 769px) {
    .ba10 {
      padding-bottom: 0px;
      .radio-btn-style {
        display: block !important ;
      }

      &.radio-buttons {
        .field.is-grouped.is-grouped-multiline .control {
          margin: 0px 5px 5px 0px;
          &:last-child {
            margin-bottom: 5px;
          }
        }
        .field-label {
          margin-right: 24px;
          padding-bottom: 0px;
          padding-top: 0.375em;
        }
      }

      .radio-buttons {
        .field.is-grouped.is-grouped-multiline {
          .control {
            max-width: 100%;
            margin: 0px 4px 8px;
            .button {
              &.btn-text {
                &.is-variant {
                  font-size: 14px;
                  line-height: 22px;
                }
              }
            }
          }
        }
      }
    }
  }
`
